<template>
    <b-row>

        <b-col md="12">
            <b-card>
                <h4 class="text-primary">{{getType(userData.user_type)}}</h4>
                <hr>
                <b-card-body>
                    <h5 class="mt-1">User Id: {{userData.user_id}}</h5>
                    <h5 class="mt-1">Name: {{userData.user_fname + userData.lastname}}</h5>
                    <h5 class="mt-1">Username: {{userData.user_username}}</h5>
                    <h5 class="mt-1">Email: {{userData.user_email}}</h5>
                    <h5 class="mt-1">Phone: {{userData.user_phone}}</h5>
                    <h5 class="mt-1">Created Date: {{userData.user_created_at}}</h5>
                </b-card-body>
                <b-row>
                    <b-col md="3">
                    <div class="demo-inline-spacing">
                        <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        block
                        variant="success"
                        >
                        Edit Details
                        </b-button>
                    </div>
                    </b-col>

                    <!-- <b-col md="3">
                    <div class="demo-inline-spacing">
                        <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        block
                        variant="danger"
                        >
                        Reject
                        </b-button>
                    </div>
                    </b-col> -->
                </b-row>
            </b-card>
        </b-col>

    </b-row>
</template>

<script>
import { ref, onUnmounted } from "@vue/composition-api";
import store from "@/store";
import router from "@/router";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BLink,
  VBToggle,
  BImg,
} from "bootstrap-vue";
import Logo from "@core/layouts/components/Logo.vue";
import Ripple from "vue-ripple-directive";
import axios from "axios";
import UserManagementServices from '@/apiServices/UserManagementServices';

export default {
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    BImg,
    Logo,
  },
  setup() {
  },
  beforeMount() {
    this.loadUserDetails();
  },
  data() {
      return {
      userData: {},
      profilePicture: "https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/demo-1/img/1.9cba4a79.png",
    };
  },
  methods: {
    getType(data){
        if(data == "seller"){
            return "SELLER";
        }else{
            return "ADMIN";
        }
    },
    loadUserDetails() {
      const { id } = this.$route.query;
      UserManagementServices.getSingleUser(id)
        .then((response) => {
          this.userData = response.data.data;
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>

<style lang="scss">
</style>
